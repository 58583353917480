.okok{
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
}
.okok-noc{
  border: 2px solid black;
  padding: 10px 20px 10px 20px;
}
.okok-noc{
  border: 2px solid black;
  padding: 10px 20px 10px 20px;
}

.text-center{
  text-decoration: underline;
}
.logo-rect1{
  width: 150px;
  height: 100px !important;
}