
.Addcustomerbutt{
  background: linear-gradient(90deg, #2767B1 0%, #112C4B 100%);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  opacity: 87%;
  text-shadow: 0px 1px 6px #ffffffcc;

}
.input-base {
  width: 100%;
  height: 40px;
}
.anchorbutton{
  border: none;
  background-color: white;
  color: blue;
}
.relative {
  position: relative;
}

.input-value{
  width: 200px;
  border-radius: 10px;
}
.imput-name{
font-family: Open Sans;
font-size: 20px;
font-weight: 400;
line-height: 60px;
letter-spacing: 0em;
text-align: center;
color: black;
}
.check {
cursor: pointer;
position: relative;
margin: auto;
width: 18px;
height: 18px;
-webkit-tap-highlight-color: transparent;
transform: translate3d(0, 0, 0);
}

.check:before {
content: "";
position: absolute;
top: -15px;
left: -15px;
width: 48px;
height: 48px;
border-radius: 50%;
background: rgba(34, 50, 84, 0.03);
opacity: 0;
transition: opacity 0.2s ease;
}

.check svg {
position: relative;
z-index: 1;
fill: none;
stroke-linecap: round;
stroke-linejoin: round;
stroke: #c8ccd4;
stroke-width: 1.5;
transform: translate3d(0, 0, 0);
transition: all 0.2s ease;
}

.check svg path {
stroke-dasharray: 60;
stroke-dashoffset: 0;
}

.check svg polyline {
stroke-dasharray: 22;
stroke-dashoffset: 66;
}

.check:hover:before {
opacity: 1;
}

.check:hover svg {
stroke: var(--accent-color, #1960d1);
}

#cbx2:checked + .check svg {
stroke: var(--accent-color, #355abf);
}

#cbx2:checked + .check svg path {
stroke-dashoffset: 60;
transition: all 0.3s linear;
}

#cbx2:checked + .check svg polyline {
stroke-dashoffset: 42;
transition: all 0.2s linear;
transition-delay: 0.15s;
}
#firsttable{
border: #c8ccd4 .2px solid !important;
background-color: rgb(249,249,249);
}
#firsttable th{
border: none !important;
}
#customer-details-table {
margin-top: 20px;
}
#customer-details-table td {
border: #c7c7c7 .2px solid !important;
}
#customer-details-table tr:nth-child(odd) {
background-color: rgb(249,249,249);
}
#viewcustomertable th{
 font-size: 12px;
}
#viewcustomertable td{
  font-size: 12px;
  font-weight: 500;
}