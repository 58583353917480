@import url('https://fonts.googleapis.com/css2?family=Times+New+Roman:wght@400;700&display=swap');


.Printcontainer {
  font-family: 'Times New Roman';
  background-color: white;
  padding: 2rem;
}


.button-group {
  display: flex;
  font-family: 'Times New Roman';
  justify-content: flex-end;
  margin-bottom: 1rem;
}


.print-button {
  display: flex;
  font-family: 'Times New Roman';
  align-items: center;
  background-color: #22c55e; 
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  transition: background-color 0.3s;
}

.print-button:hover {
  background-color: #16a34a; 
  font-family: 'Times New Roman';
}

.Download {
  display: flex;
  align-items: center;
  background-color: #3b82f6;
  color: white;
  font-family: 'Times New Roman';
  padding: 2rem 3rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  transition: background-color 0.3s;
}

.Download:hover {
  background-color: #2563eb; 
  font-family: 'Times New Roman';
}

.svgIcon {
  width: 1em;
  height: 1em;
  margin-right: 0.5rem;
  font-family: 'Times New Roman';
}

.container {
  max-width: 42rem;
  margin: 0 auto;
  padding: 1rem;
  font-family: 'Times New Roman';
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Times New Roman';

}

.header img {
  height: 3rem; 
  font-family: 'Times New Roman';
}

.header .company-info {
  margin-left: 1rem;
  font-family: 'Times New Roman';
}

.header .company-info h1 {
  font-size: 1.25rem; 
  font-weight: bold;
  font-family: 'Times New Roman';
  margin: 0;
}

.header .company-info p {
  font-size: 0.875rem; 
  font-family: 'Times New Roman';
  margin: 0.25rem 0 0 0;
}

.contact-info {
  text-align: right;
  font-size: 0.875rem;
  font-family: 'Times New Roman';
}

.contact-info p {
  font-family: 'Times New Roman';
  margin: 0.25rem 0;
}

.contact-info i {
  margin-right: 0.5rem;
  font-family: 'Times New Roman';
}

.divider {
  border-top: 5px solid #10b981; 
  margin-bottom: 1rem;
  font-family: 'Times New Roman';
}

.title {
  text-align: center;
  font-size: 1.25rem; 
  font-weight: bold;
  margin-bottom: 1rem;
  font-family: 'Times New Roman';
}

.section {
  margin-bottom: 0.5rem;
  line-height: 20px;
  font-family: 'Times New Roman';
}

.section p {
  margin: 0.1rem 0;
  font-size: 14px;
  font-family: 'Times New Roman';
}
.borfer-dive-new{
  border: 2px solid grey;
  font-family: 'Times New Roman';
}

.borfer-dive-new1{
  border: 2px solid grey;
  font-family: 'Times New Roman';
  padding-bottom: 20px;
}

.section .bold {
  font-weight: bold;
  font-family: 'Times New Roman';
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
  font-family: 'Times New Roman';
  border: 2px solid grey;
}

.table th,
.table td {
  border: 1px solid #d1d5db;
  font-family: 'Times New Roman';
  padding: 0.5rem;
  text-align: left;
  font-size: 15px;
}

.footer {
  margin-top: 2rem;
  font-family: 'Times New Roman';
}

.footer p {
  margin: 0.25rem 0;
  font-family: 'Times New Roman';
}

.footer .bold {
  font-weight: bold;
  font-family: 'Times New Roman';
}

.footer .signature {
  margin-top: 2rem;
  font-family: 'Times New Roman';
}

.footer .gstin {
  font-size: 1.2rem; 
  font-family: 'Times New Roman';
}

.color-bars {
  margin-top: 0.5rem;
  display: flex;
  font-family: 'Times New Roman';
}

.color-bars .bar {
  height: 0.4rem;

  font-family: 'Times New Roman';
  flex: 1;
}

.bar-gray {
  background-color: #1f2937;
}

.bar-blue {
  background-color: #1e3a8a; 
}

.bar-yellow {
  background-color: #d97706; 
}
.loader-container {
  font-family: 'Times New Roman';
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.loader {
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  font-family: 'Times New Roman';
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@media print {
  .button-group,
  .Download,
  .print-button,
  .svgIcon {
    display: none;
  }
  body {
    padding: 0;
  }

  .Printcontainer {
    padding: 0;
    height: 100vh !important;
  }

  .container {
    border: none;
    padding: 0;
    margin: 0;
  }
}
.logo-rect{
    width: 150px;
    
}

.box-for-recpt{
  border: 2px solid grey;
  font-family: 'Times New Roman';
  display: flex;
  justify-content: space-between;
  padding: 2px 7px 2px 7px;
}

.main-pp{
  background-color: white !important;
}

.border-new{
  border: 2px solid black
  ;
}

.content-new{
  font-size: 14px !important;
  font-weight: 900;
}

.fix-font {
  font-size: 12px !important;
  font-weight: 700 !important;
  white-space: normal; /* Ensures the text wraps to the next line */
  word-wrap: break-word; /* Break long words if needed */
  line-height: 1.2; /* Adds better readability by increasing space between lines */
}
.underline{
  text-decoration: underline;
}
.okok-text{
  margin-right: 16%;
}

.fix-fonta{
  font-size: 12px !important;
  font-weight: 900 !important;
  white-space: normal; /* Ensures the text wraps to the next line */
  word-wrap: break-word; /* Break long words if needed */
  line-height: 1.2; /* Adds better readability by increasing space between lines */
}