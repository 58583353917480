.Heroimg {
  width: 100%;
  height: 100vh;
  background-image: url("../../public/chanelPartner.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;
}

.Heroimg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #04050884 0%, #20233b 100%);
  opacity: 0.5;
  z-index: 1;
}
.head {
  font-size: 44px;
  font-weight: 600;
  font-style: italic;
  text-align: center !important;
  color: white;
  z-index: 999;
  position: relative;
  top: 35%;
}
.head2 {
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  color: white;
  z-index: 999;
  position: relative;
  top: 35%;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0f172a;
  color: white;
  position: sticky;
  z-index: 999;
  height: 75px;
  top: 0;
  z-index: 1000;
}
.link-font {
  font-size: 18px;
  margin-right: 30px;
  margin-top: 16px;
  font-weight: 400;
}
.link-font1 {
  font-size: 18px;
  margin-right: 30px;
  margin-top: 16px;
  font-weight: 400;
  background-color: #111962;
  color: white;
  border: none;
  margin-top: 25px;
}
nav .title img {
  width: 100px;
  margin-left: 5px;
}
nav ul {
  display: flex;
}
nav ul li {
  list-style: none;
  color: white;
}
nav ul li a {
  display: block;
  text-decoration: none;
  color: white;
  padding: 0.6rem;
  margin: 0 0.6rem;
}
nav ul li a:hover {
  color: rgb(118, 201, 225);
  text-decoration: none;
}

nav .menu {
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}
nav .menu span {
  height: 0.4rem;
  width: 100%;
  background-color: #fff;
  border-radius: 0.2rem;
}
@media (max-width: 992px) {
  nav .menu {
    display: flex;
    margin-top: 2px;
    right: 1.5rem !important;
    width: 2.25rem;
    height: 1.65rem;
  }
  
  nav .title img {
    width: 60px;
    margin-top: 18px;
  }
  nav {
    flex-direction: column;
    align-items: flex-start;
    height: 60px;
  }
  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
  }
  nav ul.open {
    display: flex;
    background-color: #0f172a;
  }
  nav ul li {
    width: 100%;
    text-align: center;
  }
  nav ul li a {
    margin: 0.3rem 0.6rem;
    text-decoration: none !important;
  }
}
@media (max-width:690px) {
    .head {
        font-size: 20px;
        top: 25%;
    }
    .head2 {
        font-size: 15px;
        top: 25%;
    }
    
    .Heroimg{
        height: 50vh;
    } 
}
@media (max-width: 480px) {
  nav .menu {
    margin-top: 0px;
    right: 1rem !important;
  }
  nav .title img {
    width: 60px;
    margin-top: 15px;
  }
  nav {
    height: 56px;
  }
}
.formcontainer {
  display: grid;
  grid-template-columns: 0.75fr 1.25fr;
}

.back1{
    background-image: url('../../public/backimg.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100% !important;
    width: 100%;
    position: relative;
    padding-top: 5%;
}
.formdiv {
  width: 100%;
  height: auto;
  top: 922px;
  left: 610px;
  gap: 0px;
  background-color: #000000;
  opacity: .8;
  margin-bottom: 50px;
}
.contactimg {
  width: 100%;
  height: 300px;
  object-fit: contain ;
}
.text {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: white;
}
.but {
  background-color: #111962;
  color: white;
  text-align: center;
  padding: 10px;
}
.butdiv {
  display: flex;
  justify-content: center;
}
.filldiv {
  width: 100%;
  height: 40px;
  background-color: #000000;
  margin-bottom: 10px;
  border: 1px white solid;
  color: white;
}

.filldiv::placeholder {
  color: white;
}
.formfilldiv {
  padding: 5% 10%;
}
.but2 {
  align-self: center;
  background-color: #111962;
  color: white;
  text-align: center;
  padding: 3px 25px;
  border: 1px solid white;
  font-size: 26px;
  font-weight: 500;
  line-height: 45px;
  text-align: left;
  border-radius: 5px;
  margin-top: 10px;
}
@media (max-width:767px) {
    .formcontainer{
        grid-template-columns: 1fr;
    }
}
