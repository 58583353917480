.head1{
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 24px;    
    font-weight: 300;
}
.fl-table {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    border: none;
    white-space: nowrap;
     background: #5687e170;
}
.fl-table td,
.fl-table th {
    width: 100%;
    text-align: left;
    padding: 8px;
    border-bottom: none !important;
    font-size: 12px;
}
.fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
}
.butt{
    color: rgb(137, 204, 241);
    text-decoration: underline 1px rgb(200,230,246);
}
.butt:hover{
    color: rgb(165, 214, 240);
    cursor: pointer;
}
.ReceiptHead{
    font-size: 20px;
    text-align: center;
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.printarea3{
    display: grid;
    grid-template-columns: 1.4fr .6fr;
    gap: 10px;
    margin-top: 5px;
}
.printarea4{
    width: 100%;
    height: auto;
    border: 1px solid black;
    padding: 10px;
    margin-bottom: 10px;
}
.printarea5{
    width: 100%;
    height: auto;
    border: 1px solid black;
    padding: 10px;
}
.signarea{
    font-size: 16px;
    margin-top: 60px;
}
.last{
    border: 2px solid black;
}
.welcomehead{
    margin-top: 50px;
    text-align: center;
    text-decoration: underline 3px black;
}
.right{
    text-align: right;
}
 .white{
  background-color: white;
  padding-top: 20px;
  min-height: 100vh;
}