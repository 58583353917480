body {
  margin: 0;
  overflow-x: hidden;
  background-color: #DFEBFB !important;
}
@keyframes fall-in {
  0% {
    transform: scale(3);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes plunge {
  0% {
    margin-top: -100%;
  }
  100% {
    margin-top: 15%;
  }
}
.modalbox {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  border-radius: 2px;
  background: white;
  padding: 25px 25px 15px;
  text-align: center;
}

.modalbox.success {
  border: 2px solid rgba(76,175,80,1);
}

.modalbox.error {
  border: 2px solid rgba(244,67,54,1);
}

.modalbox .icon {
  position: relative;
  margin: 0 auto;
  margin-top: -75px;
  background: rgba(76,175,80,1);
  height: 100px;
  width: 100px;
  border-radius: 50%;
  animation: fall-in .75s;
}

.modalbox.error .icon {
  background: rgba(244,67,54,1);
}

.modalbox .icon span {
  position: absolute;
  font-size: 4em;
  color: white;
  text-align: center;
  padding-top: 20px;
}

.modalbox.error .icon span {
  padding-top: 25px;
}

.modalbox button {
  transition: all .1s ease-in-out;
  border-radius: 30px;
  margin-top: 15px;
  width: 80%;
  background: transparent;
  color: rgba(76,175,80,1);
  border: 2px solid rgba(76,175,80,1);
  outline: none;
}
.center {
  display: flex;
  justify-content: center;
}
.confirm-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%; 
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm-modal1 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%; 
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-content {
  margin-left: 16%;
  padding: 16px;
  background-color: #DFEBFB !important;
  min-height: 100vh;
}
.user-profile{
text-align: right;
font-size: 30px;
font-weight: 600;
margin-right: 1%;
padding-top: 1%; 
margin-top: 40px;
}

.back{
background-color: #ebf0f5;
min-height: 100vh; 
}
.whiteback{
background-color: white;
box-shadow: 4px 4px 4px 0px #55707D;
padding: 10px;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
/* position: relative; */
margin-bottom: 20px;
}
.whiteback1 {
  background-color: white;
  box-shadow: 4px 4px 4px 0px #55707D;
  padding: 10px;
  border-radius: 7px;
  overflow-x: auto; 
  white-space: nowrap; 
}
.main-content,.formback1::-webkit-scrollbar {
  height: 7px; 
}
.main-content,.formback1::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.main-content,.formback1::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 5px; 
}
.main-content,.formback1::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.gridcontainer{
display: grid;
grid-template-columns: repeat(3,1fr);
grid-gap: 5px;
}
.delete{
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.Headtext1{
font-size: 18px;
margin-top: 10px;
margin-bottom: 10px;
font-weight: 500;
  line-height: 1.1;
  text-align: center;
}
table {
width: 100%;
border: none !important;
}

th, td {
padding: 8px;
text-align: left;
border: none !important;
font-size: 14px;
}

table th {
border-top: none !important;
/* border-bottom: 1px solid black !important ; */
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; 
}
.Printcontainer{
padding: 1px 50px;
position: relative;
background-color: white;
}
.white{
  background-color: white;
  padding-top: 20px;
  min-height: 100vh;
}
.printarea1{
border: 1px solid black;
width: 100%;
height: auto;
padding: 5px;
position: relative;
}
.printarea2{
border: 1px solid black;
width: 100%;
height: 40px;
position: relative;
}
.confirm-modal-content {
  background-color: #fff;
  padding: 10px;
  border-radius: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.confirm-modal-content h3 {
  margin-top: 0;
}
.yesbutton {
  min-width: 150px;
  margin: 10px;
  padding: 10px 20px;
  border: 2px solid #119541;
  color: black;
  cursor: pointer;
  border-radius: 30px;
  background-color: white;
}
.yesbutton:hover {
  background-color:  #119541;
  color: white;
}
.nobutton {
  min-width: 150px;
  margin: 10px;
  padding: 10px 20px;
  border:2px solid #D70000;
  color: black;
  cursor: pointer;
  border-radius: 30px;
  background-color: white;
}
.nobutton:hover {
  background-color: #D70000;
  color: white;
}
.right{
  text-align: right;
}
.ledgerarea{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.calendarWrap{
  display: inline-block;
  position: relative;
}
.calendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  border: 1px solid #ccc;
  z-index: 999;
}
@media (max-width:1205px) {
.gridcontainer{
  display: grid;
  grid-template-columns: repeat(3,30%);
  grid-gap: 20px;
}
}
@media (max-width:992px) {
.main-content {
  margin-left: 25%;
  padding: 16px;
  background-size: contain;
  background-attachment: fixed;
}
.gridcontainer{
display: grid;
grid-template-columns: repeat(2,48%);
grid-gap: 20px;
}
}
.button-group {
  margin-bottom: 20px;
}
.print-button, .pdf-button {
  padding: 10px 15px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.print-button {
  background-color: #4CAF50;
  color: white;
}
.pdf-button {
  background-color: #008CBA;
  color: white;
}
@media print {
  .button-group {
    display: none;
  }
}
.anchor{
  border: none;
  text-decoration: underline;
  background-color: white;
  color: blue;
}
.Excel {
  --color: #00A97F;
  --color2: rgb(10, 25, 30);
  padding: 10px 15px;
  background-color: transparent;
  border-radius: 6px;
  border: .3px solid var(--color);
  transition: .5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight: 400;
  font-family: 'Roboto', 'Segoe UI', sans-serif;
  text-transform: uppercase;
  color: var(--color);
 }
 .Excel::after, .Excel::before {
  content: '';
  height: 100%;
  width: 100%;
  transform: skew(90deg) translate(-50%, -50%);
  position: absolute;
  inset: 50%;
  z-index: -1;
  transition: .5s ease-out;
  background-color: var(--color);
 }
 .Excel::before {
  top: -50%;
  left: -25%;
  transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
 }
 .Excel:hover::before {
  transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
 }
 .Excel:hover::after {
  transform: skew(45deg) translate(-50%, -50%);
 }
 .Excel:hover {
  color: var(--color2);
 }
 .Excel:active {
  filter: brightness(.7);
  transform: scale(.98);
 }
 .Download {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  background-color: rgb(27, 27, 27);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.11);
}
.svgIcon {
  fill: rgb(214, 178, 255);
}
.icon2 {
  width: 18px;
  height: 5px;
  border-bottom: 2px solid rgb(182, 143, 255);
  border-left: 2px solid rgb(182, 143, 255);
  border-right: 2px solid rgb(182, 143, 255);
}
.tooltip {
  position: absolute;
  right: -105px;
  opacity: 0;
  background-color: rgb(12, 12, 12);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: .2s;
  pointer-events: none;
  letter-spacing: 0.5px;
}
.tooltip::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background-color: rgb(12, 12, 12);
  background-size: 1000%;
  background-position: center;
  transform: rotate(45deg);
  left: -5%;
  transition-duration: .3s;
}
.Download:hover .tooltip {
  opacity: 1;
  transition-duration: .3s;
}
.Download:hover {
  background-color: rgb(150, 94, 255);
  transition-duration: .3s;
}
.Download:hover .icon2 {
  border-bottom: 2px solid rgb(235, 235, 235);
  border-left: 2px solid rgb(235, 235, 235);
  border-right: 2px solid rgb(235, 235, 235);
}
.Download:hover .svgIcon {
  fill: rgb(255, 255, 255);
  animation: slide-in-top 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@keyframes slide-in-top {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@media print {
  body * {
    visibility: hidden;
  }
  #print-content, #print-content * {
    visibility: visible;
  }
  #print-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .button-group {
    display: none;
  }
}
.pie-chart-container {
  width: 100%;
  max-width: 400px; 
  margin: 0 auto;
  padding: 20px; 
  border-radius: 8px; 
}
.pie-chart-container canvas {
  max-width: 100%; 
  height: auto; 
}
.addbutton{
  width: 200px;
height: 50px;
background: #211C52;
border-radius: 6px;
color: white;
font-size: 20px;
font-weight: 400;
line-height: 23.14px;
margin-top: 10px;
}
.copyrighttext{
  text-align: left ;
}
.copyrightdiv{
  height: 50px;
  width: 100%;
  background-color: white;
  position: fixed;
  margin-top: 20px;
  left: 16%;
  bottom: 0;
  padding: 10px;
  overflow: hidden;
}
.formhead {
  width: 100%;
  height: 48px;
  background: linear-gradient(90deg, #47438E 0%, #141328 134.72%);
  border-radius: 20px;
  color: white;
  padding-left: 20px;
  padding-top: 4px;
  font-family: Kanit;
font-size: 24px;
font-weight: 300;
line-height: 38.87px;
text-align: left;
}
.formheadcenter {
  width: 100%;
  height: 48px;
  background: linear-gradient(90deg, #47438E 0%, #141328 134.72%);
  border-radius: 20px;
  color: white;
  padding-left: 20px;
  padding-top: 4px;
  font-family: Kanit;
font-size: 24px;
font-weight: 400;
line-height: 38.87px;
text-align: center;
}
.formback{
  width: 86%;
  height: auto;
  background-color: white;
  margin-left: 7%;
  border-radius: 20px;
  padding-bottom: 10px;
}
.formback1{
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 20px;
  padding-bottom: 10px;
  overflow-x: auto; 
  white-space: nowrap; 
}
.formback2{
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 20px;
  padding-bottom: 10px;
  overflow-x: auto; 
  white-space: nowrap; 
}
.formback3{
  width: 100%;
  height: auto;
  border-radius: 20px;
  background: white;
}
.between{
  display: flex;
  justify-content: space-between;
}
.custom-file-upload {
  padding: 10px;
  border: 1px solid #ccc;
  display: inline-block;
  cursor: pointer;
  background: linear-gradient(90deg, #5687F2 0%, #324E8C 100%);
  color: white;
  height: 45px;
font-size: 20px;
font-weight: 400;
line-height: 23.14px;
text-align: left;
border-radius: 5px;
}
.pright{
  display: flex;
  justify-content: end;
}
.file-upload-wrapper {
  position: relative;
  display: inline-block;
  margin-top: 5px;
}
label{
  color: #65686F;
}
.formtablehead{
  background: linear-gradient(90deg, #06133D 0%, #1033A3 100%);
  width: 100%;
border-radius: 30px;
  color: white;
  padding: 5px 30px;
}
.formtabletext{
  width: 100%;
box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
margin: 10px 0;
padding: 0 35px;
}
.customerhead{
  font-family: Kanit;
font-size: 20px;
font-weight: 400;
line-height: 29.9px;
text-align: left;
text-shadow: 0px 0px 8px #00000063;
position: relative;
}
.customerhead span{  
padding-right: 10px;
}
.customerhead:after {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  height: 0.5em;
  border-top: 1.5px solid black;
  z-index: -1;
}
.datepicker{
  width: 100%;
}
.formtablehead1 {
  background: linear-gradient(90deg, #5687F2 0%, #324E8C 100%);
  width: 100%;
  border-radius: 30px;
  color: white;
  padding: 5px 30px;
}
.tr1{
  color: white;
}
.table {
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* 10 columns */
  gap: 15px;
  width: 100%;
}
.formtabletext1 {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  margin: 10px 0;
  padding: 0 35px;
  border-radius: 20px;
}
.table-header {
  background: linear-gradient(90deg, #06133D 0%, #1033A3 100%);
  color: white;
  padding: 5px 30px;
  display: flex; 
  width: 100%;
}

.table-row {
  display: contents; /* Each .table-row acts like a grid row */
  background-color: #f9f9f9;
  padding: 10px 0;
  border-radius: 30px;
  align-items: center;
}

.table-cell {
  padding: 10px;
  text-align: left;
}
.bloginput{
  width: 100%;
  height: 40px;
}