.notification{
    background-color: rgba(129, 129, 129, 0.429);
    padding:10px 20px;
    font-family: "Roboto", "Arial", sans-serif;
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: 400;
}
.Duedatemodal{
    position: absolute;
    background-color: white;
    width: 500px;
    right: 110px;
    top: 50px;
}
.hov:hover{
    background-color: rgba(212, 212, 212, 0.221);
}