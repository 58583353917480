@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");
* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}
body,
html,
.App,
#root,

.navbar-light {
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}
.custom-control-label {
  font-weight: 400;
}
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}
.forgot-password a {
  color: #167bff;
}
table,
th,
td {
  border: 1px solid black !important;
}
.loginbutt {
  width: 196px;
  height: 46px;
  border-radius: 23px ;
  border: none;
  background: radial-gradient(50% 50% at 50% 50%, #01509D 0%, #001C37 100%);
  color: white;
}
.loginbutt1 {
  width: 120px;
  height: 30px;
  border-radius: 23px ;
  border: none;
  background: radial-gradient(50% 50% at 50% 50%, #01509D 0%, #001C37 100%);
  color: white;
  margin-left: -5px;
}
.checkbox-wrapper-33 {
  --s-xsmall: 0.625em;
  --s-small: 1.2em;
  --border-width: 1px;
  --c-primary: #222e33;
  --c-primary-20-percent-opacity: #678998;
  --c-primary-10-percent-opacity: #678998;
  --t-base: 0.4s;
  --t-fast: 0.2s;
  --e-in: ease-in;
  --e-out: cubic-bezier(0.11, 0.29, 0.18, 0.98);
}
.checkbox-wrapper-33 .visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.checkbox-wrapper-33 .checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
}
.checkbox-wrapper-33 .checkbox + .checkbox {
  margin-top: var(--s-small);
}
.checkbox-wrapper-33 .checkbox__symbol {
  display: inline-block;
  display: flex;
  margin-right: calc(var(--s-small) * 0.7);
  border: var(--border-width) solid var(--c-primary);
  position: relative;
  border-radius: 0.1em;
  width: 1.5em;
  height: 1.5em;
  transition: box-shadow var(--t-base) var(--e-out),
    background-color var(--t-base);
  box-shadow: 0 0 0 0 var(--c-primary-10-percent-opacity);
}
.checkbox-wrapper-33 .checkbox__symbol:after {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  width: 0.25em;
  height: 0.25em;
  background-color: var(--c-primary-20-percent-opacity);
  opacity: 0;
  border-radius: 3em;
  transform: scale(1);
  transform-origin: 50% 50%;
}
.checkbox-wrapper-33 .checkbox .icon-checkbox {
  width: 1em;
  height: 1em;
  margin: auto;
  fill: none;
  stroke-width: 3;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  color: var(--c-primary);
  display: inline-block;
}
.checkbox-wrapper-33 .checkbox .icon-checkbox path {
  transition: stroke-dashoffset var(--t-fast) var(--e-in);
  stroke-dasharray: 30px, 31px;
  stroke-dashoffset: 31px;
}
.checkbox-wrapper-33 .checkbox__textwrapper {
  margin: 0;
  color: black;
}
.checkbox-wrapper-33 .checkbox__trigger:checked + .checkbox__symbol:after {
  -webkit-animation: ripple-33 1.5s var(--e-out);
  animation: ripple-33 1.5s var(--e-out);
}
.checkbox-wrapper-33
  .checkbox__trigger:checked
  + .checkbox__symbol
  .icon-checkbox
  path {
  transition: stroke-dashoffset var(--t-base) var(--e-out);
  stroke-dashoffset: 0px;
}
.checkbox-wrapper-33 .checkbox__trigger:focus + .checkbox__symbol {
  box-shadow: 0 0 0 0.25em var(--c-primary-20-percent-opacity);
}
@-webkit-keyframes ripple-33 {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(20);
  }
}
@keyframes ripple-33 {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(20);
  }
}

.slidewhite{
  background: white;
  width: 130px;
height: 130px;
border-radius: 50%;
position: relative;
top: 50%;
right: -30px;
cursor: pointer;
}
.slideblue{
  background: linear-gradient(236.88deg, #01509D 30.26%, #001C37 127.35%);
  width: 110px;
height: 110px;
border-radius: 50%;
position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}
.background {
  position: relative;
  width: 100%;
  height: 100vh;
  object-fit: contain cover;
  transition: width 1s ease; 
}
.loginhead{
  font-size: 60px;
  font-weight: 600;
  text-align: left;
  width: 50%;
  position: absolute;
  top: 25%;
  color: white;
}
.loginsubhead{
  font-size: 24px;
  font-weight: 400;
  line-height: 32.33px;
  text-align: left;
  width: 50%;
  position: absolute;
  top: 38%;
  color: white;
}
.logintext{
  font-family: Kanit;
font-size: 18px;
font-weight: 300;
line-height: 27.33px;
text-align: left;
width: 50%;
position: absolute;
top: 45%;
color: white;
}
.loginsubhead.clicked{
  width: 35%;
}
.loginhead.clicked{
  width: 35%;
}
.logintext.clicked{

width: 35%;

}
.white1{
  background-color: white;
}
.background.clicked {
  width: 50%;
  height: 100vh;
  object-fit: cover;
}

.auth-wrapper {
  position: absolute;
  right: -2%;
  top: 40%;
  transition: right  1s ease; 
}
.auth-wrapper.clicked {
  position: absolute;
  right: 47%;
  top: 40%;
}

.slidingimg {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 38%;
  transform: translate(-50%, -50%) rotate(270deg);
  transition: transform 1s ease; 
}

.slidingimg.rotate {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg); 
}
.login-input{
  width: 100%;
height: 42px;
padding-left: 10px;
border-radius: 48.5px;
}
.loginbutt {
  width: 196px;
  height: 46px;
  border-radius: 23px ;
  border: none;
  background: radial-gradient(50% 50% at 50% 50%, #01509D 0%, #001C37 100%);
  color: white;
}
.login {
  margin-left: 10%;
  width: 30%;
  position: absolute;
  top: 20%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 3s ease-in; 
}

.login.clicked {
  opacity: 1; 
  visibility: visible;
}
.welcomehead1{
  font-family: kanit;
font-size: 40px;
font-weight: 700;
line-height: 59.8px;  
text-align: left;
}

.clickhere{
  display: block;
  font-family: Kanit;
font-size: 24px;
font-weight: 400;
line-height: 52.33px;
text-align: left;
color: white;
position: absolute;
top: 47%;
right: 10%;
animation: shake 2s cubic-bezier(.36, .07, .19, .97) infinite;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.clickhere.clicked{
  display: none;
}