 .ReceiptHead{
    font-size: 20px;
    text-align: center;
    position: absolute;
    top: 50%;
  transform: translate(-50%, -50%);
}
.printarea3{
    display: grid;
    grid-template-columns: 1.4fr .6fr;
    gap: 10px;
    margin-top: 5px;
}
.printarea4{
    width: 100%;
    height: auto;
    border: 1px solid black;
    padding: 10px;
    margin-bottom: 10px;
}
.printarea5{
    width: 100%;
    height: auto;
    border: 1px solid black;
    padding: 10px;
}
.signarea{
    font-size: 16px;
    margin-top: 60px;
}
.last{
    border: 2px solid black;
}
.welcomehead{
    margin-top: 50px;
    text-align: center;
    text-decoration: underline 3px black;
}
.right{
    text-align: right;
}
 .white{
  background-color: white;
  padding-top: 20px;
  min-height: 100vh;
}
.dipnew{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}
.dipnews{
    display: flex;
    justify-content: space-between;
    margin-top: 40%;
}
